import React, { Component } from 'react';
import { GET_PARAMETERS, POST_PARAMETERS } from "../common/Http";
import './Account.css';

class AccountApi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiKey: '',
            loading: true,
            certificatesFromYear: 2005,
            certificatesToYear: new Date().getFullYear(),
            certificatesResponse: ''
        };
    }

    componentDidMount() {
        this.loadApiKey();
    }

    loadApiKey() {
        fetch(`${process.env.REACT_APP_API_URL}/account/api/key`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({
                apiKey: data['api_key'],
                loading: false
            }));
    }

    generateApiKey(e) {
        e.preventDefault();
        this.setState({
            loading: true
        }, () => {
            fetch(`${process.env.REACT_APP_API_URL}/account/api/key`, POST_PARAMETERS)
                .then(() => {
                    this.setState({
                        loading: false
                    }, () => this.loadApiKey());
                });
        });
    }

    deleteApiKey(e) {
        e.preventDefault();
        this.setState({
            loading: true
        }, () => {
            fetch(`${process.env.REACT_APP_API_URL}/account/api/key/delete`, POST_PARAMETERS)
                .then(() => {
                    this.setState({
                        loading: false
                    }, () => this.loadApiKey());
                });
        });
    }

    requestCertificates(e) {
        e.preventDefault();
    }

    render() {
        return (
            <div className="content account-content col-sm-12 col-md-9 col-lg-10">
                <h2>Udostępnianie danych konta</h2>
                <p>Klucz API pozwala w bezpieczny i w kontrolowany przez Ciebie sposób udostępnić dane na innych portalach.</p>
                <p>W razie wątpliwości, możesz w każdej chwili usunąć swój klucz lub wygenerować nowy.</p>
                {this.state.loading ?
                    <div className="spinner" /> :
                    <div>
                        {this.state.apiKey ?
                            <>
                                <form className="content-form">
                                    <div className="form-row">
                                        <input type="text" id="apikey"
                                            size="38"
                                            value={this.state.apiKey}
                                            onChange={e => this.setState({ email: e.target.value })} />
                                    </div>
                                    <div className="form-row">
                                        <button className="small primary"
                                            onClick={e => this.generateApiKey(e)}>Wygeneruj nowy klucz</button>
                                        <button className="small secondary"
                                            onClick={e => this.deleteApiKey(e)}>Usuń klucz</button>
                                    </div>
                                    <div className="form-row">
                                        <p><b>Uwaga! Zmiana klucza przerwie działanie dla udostępnionych już danych.</b></p>
                                    </div>
                                </form>
                                <h3>Pobieranie listy certyfikatów</h3>
                                <form className="content-form api-form">
                                    <div className="form-row">
                                        <label htmlFor="certificate-from-year">Od roku:</label>
                                        <select id="certificate-from-year"
                                            value={this.state.certificatesFromYear}
                                            onChange={e => this.setState({ certificatesFromYear: e.target.value })}>
                                            {[...Array(new Date().getFullYear() - 2004).keys()].map(key =>
                                                <option key={key} value={2005 + key}>{2005 + key}</option>)}
                                        </select>
                                        <label htmlFor="certificate-from-year">Do roku:</label>
                                        <select id="certificate-to-year"
                                            value={this.state.certificatesToYear}
                                            onChange={e => this.setState({ certificatesToYear: e.target.value })}>
                                            {[...Array(new Date().getFullYear() - 2004).keys()].map(key =>
                                                <option key={key} value={2005 + key}>{2005 + key}</option>)}
                                        </select>
                                    </div>
                                </form>
                                <p><b><a href={`https://e-dukacja.pl/share/index.php/certificates?key=${this.state.apiKey}&from=${this.state.certificatesFromYear}&to=${this.state.certificatesToYear}`}>share/certificates?key={this.state.apiKey}&from={this.state.certificatesFromYear}&to={this.state.certificatesToYear}</a></b></p>
                                
                            </> :
                            <>
                                <button className="primary"
                                    onClick={e => this.generateApiKey(e)}>Wygeneruj nowy klucz API</button>
                            </>}
                    </div>}
            </div>
        );
    }
}

export default AccountApi;
