import React, {Component} from 'react';
import './Main.css';
import Enrollments from "../enrollments/Enrollments";
import News from "../news/News";
import Logged from "../logged/Logged";
import {Link} from "react-router-dom";
import MyStats from "./MyStats";
import Cookies from 'universal-cookie';

const EMPLOYMENT_ALERT_KEY = 'employment_alert_key';

class Main extends Component {

    // noinspection JSValidateTypes
    _cookies = new Cookies();

    constructor(props) {
        super(props);
        this.state = {
            userData: props.userData,
            passwordAlert: props.passwordAlert,
            employmentAlert: !this._cookies.get(EMPLOYMENT_ALERT_KEY) && props.employmentAlert,
            emailAlert: props.emailAlert,
            removePasswordAlert: props.removePasswordAlertCallback,
            removeEmploymentAlert: props.removeEmploymentAlertCallback
        };
    }

    clearPasswordAlert() {
        this.setState({
            passwordAlert: false,
        }, () => this.state.removePasswordAlert());
    }

    clearEmploymentAlert() {
        this._cookies.set(EMPLOYMENT_ALERT_KEY, true);
        this.setState({
            employmentAlert: false,
        }, () => this.state.removeEmploymentAlert());
    }

    render() {
        return (
            <>
                <div className="content col-sm-12 col-md-5 col-lg-7">
                    {this.state.emailAlert ?
                        <div className="content-box content-box-alert">
                            <p>
                                <b>Uwaga!</b> Twój adres e-mail jest nieprawidłowy lub nie otrzymuje wiadomości z e-dukacji.<br/>
                                <b>Prosimy o aktualizację adresu e-mail w zakładce</b> <Link to='/account'>Moje konto</Link>.
                            </p>
                        </div> : ''}
                    {this.state.passwordAlert ?
                        <div className="content-box content-box-alert">
                            <p>
                                Twoje hasło nie spełnia wymagań bezpieczeństwa.<br/>
                                Prosimy o przejście na zakładkę <Link to='/account'>Moje konto</Link> aby
                                zaktualizować hasło.
                            </p>
                            <b className="content-box-alert-close"
                               onClick={() => this.clearPasswordAlert()}>x</b>
                        </div> : ''}
                    {this.state.employmentAlert ?
                        <div className="content-box content-box-alert">
                            <p>
                                Prosimy o aktualizację danych dotyczących zatrudnienia.<br/>
                                Formularz jest dostępny w zakładce <Link to='/account'>Moje konto</Link> w sekcji
                                "Zmiana danych zawodowych".<br/>
                                Jeżeli Państwa dane są właściwe i aktualne, prosimy o zamknięcie wyświetlanego komunikatu.
                            </p>
                            <b className="content-box-alert-close"
                               onClick={() => this.clearEmploymentAlert()}>x</b>
                        </div> : ''}
                    <div className="content-box">
                        <h2>Aktualne</h2>
                        <Enrollments linked={true} type="current"/>
                        <h2>Zakończone</h2>
                        <Enrollments linked={true} type="finished"/>
                        <h2>Oczekiwanie na przyjęcie</h2>
                        <Enrollments linked={false} type="pending"/>
                    </div>
                    {this.state.userData.login !== 'orlik' ?
                        <MyStats userData={this.state.userData}/> :
                        ''}
                    <Logged/>
                </div>
                <News logged={true}/>
            </>
        );
    }
}

export default Main;