import React, { Component } from 'react';
import { NavLink, Route } from "react-router-dom";
import './Account.css';
import AccountSettings from './AccountSettings';
import AccountApi from './AccountApi';

class Account extends Component {
    render() {
        return (
            <div className="content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    <ul className="content-navigation">
                        <li><NavLink exact={true} to='/account'>Ustawienia konta</NavLink></li>
                        <li><NavLink exact={true} to='/account/api'>Udostępnianie danych</NavLink></li>
                    </ul>
                    <Route exact path='/account'
                        render={props => <AccountSettings {...props} />} />
                    <Route exact path='/account/api'
                        render={props => <AccountApi {...props} />} />
                </div>
            </div>
        );
    }
}

export default Account;
